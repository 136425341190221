import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useAnnouncementCategoryList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refActiveAnnouncementsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'title',
      label: 'Title',
      width: '7%',
      sortable: true,
    },
    {
      key: 'content',
      label: 'Content',
      sortable: true,
    },
    {
      key: 'departments',
      label: 'Department',
      sortable: false,
    },
    {
      key: 'categories',
      label: 'Category',
      sortable: false,
    },
    {
      key: 'highlight',
      label: 'Highlights',
      sortable: true,
    },
    {
      key: 'createdBy',
      label: 'Created by',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalAnnouncements = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const announcementCategoryFilter = ref([])
  if (Array.isArray(route.query.announcementCategory)) {
    announcementCategoryFilter.value = route.query.announcementCategory
  } else if (route.query.announcementCategory) {
    announcementCategoryFilter.value = [route.query.announcementCategory]
  }
  const highlightFilter = ref([])
  if (Array.isArray(route.query.highlight)) {
    highlightFilter.value = route.query.highlight
  } else if (route.query.highlight) {
    highlightFilter.value = [route.query.highlight]
  }

  const toDeletedID = ref(null)
  const type = ref('pending')
  const announcementData = ref([])
  const startDateFilter = ref(route.query.startDate || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refActiveAnnouncementsTable.value ? refActiveAnnouncementsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAnnouncements.value,
    }
  })

  const refetchData = () => {
    refActiveAnnouncementsTable.value.refresh()
  }

  watch([searchQuery, announcementCategoryFilter, highlightFilter, startDateFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchAnnouncements = (ctx, callback) => {
    store
      .dispatch('agencies-active-announcements/fetchAnnouncements', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        category: announcementCategoryFilter.value,
        highlight: highlightFilter.value,
        startDate: startDateFilter.value,
        type: type.value,
      },
      { root: true })
      .then(response => {
        const { announcements, total } = response.data
        announcementData.value = announcements
        callback(announcements)
        totalAnnouncements.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              category: announcementCategoryFilter.value,
              highlight: highlightFilter.value,
              startDate: startDateFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteAnnouncement = () => {
    store
      .dispatch('agencies-active-announcements/deleteAnnouncement', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAnnouncementStatusVariant = status => {
    if (status === 'published') return 'success'
    if (status === 'draft') return 'secondary'
    if (status === 'unpublished') return 'danger'
    if (status === 'pending') return 'warning'
    return 'primary'
  }

  return {
    fetchAnnouncements,
    tableColumns,
    perPage,
    currentPage,
    totalAnnouncements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refActiveAnnouncementsTable,

    resolveAnnouncementStatusVariant,
    refetchData,

    toDeletedID,
    deleteAnnouncement,
    announcementData,

    // Extra Filters
    announcementCategoryFilter,
    highlightFilter,
    startDateFilter,
  }
}
